<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="状态" prop="status">
                <el-select v-model="form.status">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="正常" value="1"></el-option>
                    <el-option label="禁用" value="2"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌颜色" prop="color">
                <el-select v-model="form.color">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="t in colorList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车辆类型" prop="vType">
                <el-select v-model="form.vType">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="t in vTypeList" :key="t.iValue" :label="t.iValue" :value="t.rValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="燃料种类" prop="energyT">
                <el-select v-model="form.energyT">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="t in energyTList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车辆型号" prop="model">
                <el-input v-model="form.model"></el-input>
            </jy-query-item>
            <jy-query-item label="车架号" prop="vin">
                <el-input v-model="form.vin"></el-input>
            </jy-query-item>
            <jy-query-item label="发动机号" prop="engineNo">
                <el-input v-model="form.engineNo"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesBasicInfoVehicleList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesBasicInfoVehicleReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesBasicInfoVehicleInsert')">新增</el-button>
                    <export-excel
                        url="/unitInfo/export"
                        fileName="车辆"
                        fileType=".xls"
                        size="small"
                        v-if="btnexist('wastesBasicInfoVehicleExport') && false"
                    ></export-excel>
                    <el-button size="small" type="primary" v-if="btnexist('wastesBasicInfoVehicleImport') && false">导入</el-button>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="车牌号" prop="plateNum" min-width="120"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa"  min-width="160"></jy-table-column>
                <jy-table-column label="状态" prop="status" min-width="80">
                    <template v-slot="scope">{{ scope.row.status === "1" ? "正常" : "禁用" }}</template>
                </jy-table-column>
                <jy-table-column label="车牌颜色" prop="color" min-width="80"></jy-table-column>
                <jy-table-column label="车辆类型" prop="vType" width="100" min-width="120">
                    <template v-slot="scope">{{ formatVType(scope.row.vType) }}</template>
                </jy-table-column>
                <jy-table-column label="燃烧种类" prop="energyT" min-width="120"></jy-table-column>
                <jy-table-column label="车牌厂商" prop="vendor" min-width="120"></jy-table-column>
                <jy-table-column label="车辆型号" prop="model" min-width="120"></jy-table-column>
                <jy-table-column label="车架号" prop="vin" min-width="120"></jy-table-column>
                <jy-table-column label="发动机号" prop="engineNo" min-width="120"></jy-table-column>
                <jy-table-column label="产地" prop="orignal" min-width="120"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa" min-width="120"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" width="160"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <add-update ref="addUpdate" @update="getList"></add-update>
        <institutions-tree :showType="[1]" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
        <track-replay ref="trackReplay"></track-replay>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import AddUpdate from "@/components/pages/admin/basic/vehicle/AddUpdate.vue";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    import trackReplay from "@/components/pages/admin/basic/vehicle/trackReplay/trackReplay";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.getList();
            this.setOperateList();
            this.initDicts();
        },
        mixins: [btnMixins],
        methods: {
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictplatecolor").then(res => {
                    this.colorList = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictvehicletype").then(res => {
                    this.vTypeList = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictfueltype").then(res => {
                    this.energyTList = res.detail;
                });
            },
            setOperateList() {
                let l = [
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleAddUpdate,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoVehicleUpdate");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: () => {
                            return this.btnexist("wastesBasicInfoVehicleDelete");
                        }
                    },
                    {
                        name: "轨迹回放",
                        icon: "el-icon-delete",
                        fun: row => {
                            row.vehicleNo = row.plateNum;
                            this.$refs.trackReplay.init(row, "waybill");
                        },
                        isShow: () => {
                            return true;
                        }
                    }
                ];
                this.operateList = l;
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            formatVTypeValue(val) {
                return val === null ? null : Number(val);
            },
            getList() {
                this.loading = true;
                let url = "/base/vehicle/pageQuery";
                this.$http
                    .post(url, {
                        vehicleInfo: {
                            ...this.form,
                            vType: this.formatVTypeValue(this.form.vType)
                        },
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize
                    })
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.sysOrg,
                                ...v.vehicleEx,
                                ...v.vehicleAttach,
                                ...v.vehicleInfo
                            };
                        });
                        console.log(this.dataList, 'dataList');
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            add() {
                this.handleAddUpdate();
            },
            handleAddUpdate(options) {
                if (options) {
                    options.vType = options.vType + "";
                }
                this.$refs.addUpdate.init(options);
            },
            handleDelete(row) {
                this.$confirm("是否删除该车辆?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/base/vehicle/delete";
                        this.$http.post(url, row.vehicleId, { isRequestParam: false }).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            formatVType(type) {
                const t = this.vTypeList.find(v => v.rValue == type);
                if (t) {
                    return t.iValue;
                }
                return "-";
            },
            formatTime(val) {
                return this.$util.formatMacTime(val);
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,

                form: {
                    plateNum: "",
                    orgId: "",
                    orgNa: "",
                    status: "",
                    color: "",
                    vType: null,
                    energyT: "",
                    model: "",
                    vin: "",
                    engineNo: ""
                },

                operateList: [],

                pageIndex: 1,
                pageSize: 10,

                colorList: [],
                vTypeList: [],
                energyTList: [],

                btnMenuId: "wastesBasicInfoVehicle"
            };
        },
        components: {
            ExportExcel,
            AddUpdate,
            institutionsTree,
            trackReplay
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
