<template>
    <el-dialog :visible.sync="visible" :title="title" @close="close">
        <el-form ref="vehicleForm" :model="model" :rules="rules" label-width="130px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车牌号" prop="plateNum">
                        <el-input v-model="model.plateNum"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="所属机构" prop="orgId">
                        <div @click="selectInstitutions">
                            <el-input placeholder="请选择" v-model="model.orgNa"></el-input>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="状态" prop="status">
                        <el-select v-model="model.status">
                            <el-option label="正常" value="1"></el-option>
                            <el-option label="禁用" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车牌颜色" prop="color">
                        <el-select v-model="model.color">
                            <el-option v-for="t in colorList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆类型" prop="vType">
                        <el-select v-model="model.vType">
                            <el-option v-for="t in vTypeList" :key="t.iValue" :label="t.iValue" :value="t.rValue"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="燃料种类" prop="energyT">
                        <el-select v-model="model.energyT">
                            <el-option v-for="t in energyTList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆品牌" prop="vendor">
                        <el-input v-model="model.vendor"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="车辆型号" prop="model">
                        <el-input v-model="model.model"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车架号" prop="vin">
                        <el-input v-model="model.vin"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="发动机号" prop="engineNo">
                        <el-input v-model="model.engineNo"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="产地" prop="orignal">
                        <el-input v-model="model.orignal"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="总质量（kg）" prop="wTotal">
                        <el-input v-model="model.wTotal"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="整备质量（kg）" prop="wCurb">
                        <el-input v-model="model.wCurb"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="核定载质量（kg）" prop="wLoad">
                        <el-input v-model="model.wLoad"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="外型尺寸（mm）" prop="sizeT">
                        <el-input v-model="model.sizeT"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="货厢尺寸（mm）" prop="sizeBox">
                        <el-input v-model="model.sizeBox"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆容积（方）" prop="volume">
                        <el-input v-model="model.volume"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="准乘人数（人）" prop="pasNu">
                        <el-input v-model="model.pasNu"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="model.remark"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="营运证" prop="opLiceUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleOpLiceUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.opLiceUrl" :src="model.opLiceUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="行驶证" prop="regUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleRegUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.regUrl" :src="model.regUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆照片" prop="vehicleUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleVehicleUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.vehicleUrl" :src="model.vehicleUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="其他" prop="otherUrl">
                        <el-upload
                            class="avatar-uploader"
                            :action="uploadImgUrl"
                            :show-file-list="false"
                            :on-success="handleOtherUrlAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            :headers="uploadHeader"
                        >
                            <img v-if="model.otherUrl" :src="model.otherUrl" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div class="handle_buttons">
            <el-button type="primary" size="small" @click="saveM">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>

        <institutions-tree :showType="[1]" :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </el-dialog>
</template>

<script>
    import { cloneDeep } from "lodash";
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    export default {
        methods: {
            close() {
                this.$refs.vehicleForm.resetFields();
                this.model.orgNa = "";
            },
            saveM() {
                this.$refs.vehicleForm.validate(valide => {
                    if (valide) {
                        let url = "/base/vehicle/add";
                        if (this.isEdit) {
                            url = "/base/vehicle/update";
                        }
                        let vType = Number(this.model.vType);
                        let vehicleInfo = {
                            ...this.model,
                            vType
                        };
                        delete vehicleInfo.createT;
                        delete vehicleInfo.updateT;
                        this.$refs.vehicleForm.validate(valide => {
                            if (valide) {
                                this.$http
                                    .post(
                                        url,
                                        this.$util.filterEmptyValue({
                                            vehicleInfo,
                                            vehicleAttach: {
                                                vehicleId: this.model.vehicleId,
                                                opLiceUrl: this.model.opLiceUrl,
                                                vehicleUrl: this.model.vehicleUrl,
                                                regUrl: this.model.regUrl,
                                                otherUrl: this.model.otherUrl
                                            },
                                            vehicleEx: {
                                                vehicleId: this.model.vehicleId,
                                                remark: this.model.remark
                                            }
                                        })
                                    )
                                    .then(res => {
                                        this.$message.success({
                                            message: res.msg
                                        });
                                        this.$emit("update");
                                        this.visible = false;
                                    });
                            }
                        });
                    }
                });
            },
            setUploadHeader() {
                let header = {
                    token: this.$util.getStor("token")
                };
                this.uploadHeader = header;
            },
            init(options) {
                if (options) {
                    this.isEdit = true;
                    this.$nextTick(() => {
                        this.model = cloneDeep({
                            ...options,
                            orgId: options.orgId,
                            orgNa: options.orgNa,
                            opLiceUrl: options.opLiceUrl,
                            vehicleUrl: options.vehicleUrl,
                            regUrl: options.regUrl,
                            otherUrl: options.otherUrl
                        });
                    });

                    this.title = "编辑";
                } else {
                    this.isEdit = false;
                    this.title = "新增";
                }

                this.visible = true;
            },
            addInstitutions(data) {
                this.model.orgId = data.orgId;
                this.model.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || file.type === "image/png";

                if (!isJPG) {
                    this.$message.error("图片只能是JPG或PNG格式");
                }
                return isJPG;
            },
            handleOtherUrlAvatarSuccess({ detail }) {
                this.model.otherUrl = detail;
            },
            handleOpLiceUrlAvatarSuccess({ detail }) {
                this.model.opLiceUrl = detail;
            },
            handleVehicleUrlAvatarSuccess({ detail }) {
                this.model.vehicleUrl = detail;
            },
            handleRegUrlAvatarSuccess({ detail }) {
                this.model.regUrl = detail;
            }
        },
        mounted() {
            this.setUploadHeader();
        },
        data() {
            return {
                visible: false,

                title: "新增",
                isEdit: false,
                uploadImgUrl: "/iwastes-admin/sys/user/uploadLogo",
                uploadHeader: {},

                model: {
                    plateNum: "",
                    orgId: "",
                    orgNa: "",
                    status: "",
                    color: "",
                    vType: "",
                    energyT: "",
                    vendor: "",
                    model: "",
                    vin: "",
                    engineNo: "",
                    orignal: "",
                    wTotal: "",
                    wCurb: "",
                    wLoad: "",
                    sizeT: "",
                    sizeBox: "",
                    volume: "",
                    pasNu: "",
                    remark: "",
                    opLiceUrl: "",
                    regUrl: "",
                    vehicleUrl: "",
                    otherUrl: ""
                },

                rules: {
                    plateNum: [{ required: true, message: "车牌号不为空", trigger: "blur" }],
                    status: [{ required: true, message: "状态不为空", trigger: "blur" }],
                    orgId: [{ required: true, message: "所属机构不为空", trigger: "change" }],
                    color: [{ required: true, message: "车牌颜色不为空", trigger: "change" }],
                    vType: [{ required: true, message: "车辆类型不为空", trigger: "change" }],
                    energyT: [{ required: true, message: "燃料种类不为空", trigger: "change" }]
                }
            };
        },
        components: {
            institutionsTree
        },
        computed: {
            colorList() {
                return this.$parent.colorList;
            },
            vTypeList() {
                return this.$parent.vTypeList;
            },
            energyTList() {
                return this.$parent.energyTList;
            }
        }
    };
</script>
<style scoped>
    .handle_buttons {
        text-align: center;
    }
</style>
